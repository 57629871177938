import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import zh from "antd/es/locale/zh_CN";
import "./axios";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ConfigProvider locale={zh}>
    <App />
  </ConfigProvider>
);
