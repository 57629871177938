import { Button, Checkbox, Col, Form, Row } from "antd";
import { useState } from "react";

interface aclistType {
  id: string;
  name: string;
  remark: string;
}
export const RelateCmp = (props: any) => {
  let { EditFn, labelkey, EditPlat, accountlist, name } = props;
  const [account] = useState(accountlist);
  const [select, setSelect] = useState([]);

  const onChange = async (checkedValues: any) => {
    setSelect(checkedValues);
  };
  return (
    <div className="relate-layout">
      <div className="relate-box">
        <Form.Item name={name}>
          <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
            <Row gutter={24}>
              {account.map((i: aclistType, index: number) => {
                return (
                  <Col span={8} key={i.id} style={{ margin: "5px 0" }}>
                    <Checkbox value={i.id}>{i.name} </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </div>
      <div className="submitbtn">
        <Button
          style={{ marginRight: "5px" }}
          onClick={() => {
            EditFn(labelkey, true);
          }}
        >
          取消
        </Button>
        <Button
          type="primary"
          onClick={() => {
            EditPlat(labelkey, select);
          }}
        >
          确认
        </Button>
      </div>
    </div>
  );
};
