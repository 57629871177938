import { Button, message, Popconfirm } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { Forms } from "../../component/forms";
import {
  AddAccountPost,
  DeletAccount,
  EditAccount,
  GetIdAccount,
} from "./accountApi";

const AddAcountModal = (props: any) => {
  const params = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [reloadFn, contextdata] = useOutletContext<
    [
      (params: any) => void,
      {
        darasSource: any[];
        loading: boolean;
        pageNumber: number;
        pageSize: number;
        total: number;
      }
    ]
  >();
  let { pageNumber, pageSize } = contextdata;
  const componentaArr = [
    {
      type: "input",
      label: "账户名称",
      key: "name",
      action: "input",
      config: { maxLength: 20 },
      required: [
        {
          required: true,
          message: "请填写账户名称",
        },
        {
          validator: (_: any, value: string) => {
            return value
              ? /^\s+$/g.test(value)
                ? Promise.reject("请填写账户名称")
                : Promise.resolve()
              : Promise.resolve();
          },
        },
      ],
    },
    {
      type: "input",
      label: "备注",
      key: "remark",
      action: "input",
      config: { maxLength: 100 },
    },
  ];
  let titleText = params.id ? "账户详情" : "添加账户";

  const reload = useCallback(async (id: any) => {
    let res = await GetIdAccount(id);
    if (res.code !== 0) {
      message.error(res.message);
      return;
    } else {
      setData(res.data);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      reload(params.id);
    }
  }, [reload, params]);
  const submit = async (values: { name: string; remark: string }) => {
    let res = await AddAccountPost(values);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("添加成功");
      navigate(-1);
      reloadFn({ pageSize, pageNumber });
    }
  };
  const submitAccount = async (value: any) => {
    let res = await EditAccount(params.id, value);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("修改成功", 0.4);
      reload(params.id); //刷新当前弹窗数据
      reloadFn({ pageNumber, pageSize }); //刷新Table
    }
  };

  const deletaccount = async (id: any) => {
    let res = await DeletAccount(id);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("删除成功");
      navigate(-1); //关闭弹窗，回到上个路由
      reloadFn({ pageNumber, pageSize }); //刷新Table
    }
  };
  const DeletCmp = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Popconfirm
        title="是否确认删除？"
        onConfirm={() => deletaccount(params.id)}
      >
        <Button type="link" style={{ fontSize: "16px" }}>
          删除此账户
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <>
      <Forms
        modalTitle={titleText}
        width={"1060px"}
        onSubmit={submit}
        initValues={data}
        submitEdit={submitAccount}
        componentaArr={componentaArr}
        DeletCmp={DeletCmp}
      />
    </>
  );
};

export { AddAcountModal };
