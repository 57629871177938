import axios from "axios";
// import { ResponseRecord, PlatDataType } from "./platinterface";

export const Addplatpost = async (data: {
  name: string;
  currency: string;
  remark?: string;
}) => {
  let res = await axios.post("/finance/platforms", data);
  return res.data;
};

export const GetPlatList = async (params: any) => {
  let res = await axios.get("/finance/platforms", { params });
  return res.data;
};
export const GetIdPlat = async (id: number) => {
  let res = await axios.get(`/finance/platforms/${id}`);
  return res.data;
};

export const Editplat = async (
  id: string | undefined,
  data: {
    name?: string;
    currency?: string;
    remark?: string;
    relatedToAccounts?: [];
    dataTableColumns?: number[];
  }
) => {
  let res = await axios.put(`/finance/platforms/${id}`, data);
  return res.data;
};

export const DeletPlat = async (id: string | undefined) => {
  let res = await axios.delete(`/finance/platforms/${id}`);
  return res.data;
};
