import {
  DndContext,
  DragOverlay,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { useState } from "react";
import { Item, SortableItem } from "./sortableItem";

const DragSort = (props: any) => {
  let { sortArr: items, data, onDragEnd } = props;
  const [activeId, setActiveId] = useState<number>(0);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={onDragEnd}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        {items.map((i: any) => {
          let json = data.find((j: any) => j.id === i) || {};
          return (
            <SortableItem id={i} key={i}>
              {json.children}
            </SortableItem>
          );
        })}
      </SortableContext>
      <DragOverlay modifiers={[restrictToWindowEdges]}>
        {activeId ? (
          <Item id={activeId}>
            {data.find((j: any) => j.id === activeId).children}
          </Item>
        ) : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart(event: any) {
    console.log(event);
    const { active } = event;
    setActiveId(active.id);
  }
};

export { DragSort };
