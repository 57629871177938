import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { DragSort } from "./dragSort";
import { EditOutlined } from "@ant-design/icons";
import { arrayMove } from "@dnd-kit/sortable";
import { AddDatatext, datatextType, GetDatatextList } from "./dataTextApi";

// interface DataType {
//   [key: string]: boolean;
// }

const DataTableCmp = (props: any) => {
  let { EditFn, labelkey, setVisible, EditPlat, defaultValue, dataText } =
    props;
  const [selected, setSelect] = useState();
  const [addtext, setText] = useState<number[]>(defaultValue);
  const [list, setList] = useState<datatextType[]>([]);
  const [editText, setEditDta] = useState<datatextType>();
  const [modalvisible, setModalVIsible] = useState<boolean>(false);
  const AddText = (n?: number) => {
    if (!n) {
      message.info("请先选择字段");
      return;
    }
    setText((state) => [...state, n]);
  }; //选择数据表字段
  const RemoveText = (n: number) => {
    setText((state) => state.filter((i: any) => i !== n));
  }; //移除所选择的数据表字段

  useEffect(() => {
    setList(dataText);
  }, [dataText]);
  const handleDragEnd = async (event: any) => {
    let { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      const oldIndex = addtext.findIndex((i: any) => i === active.id);
      const newIndex = addtext.findIndex((i: any) => i === over.id);
      let newArr = arrayMove(addtext, oldIndex, newIndex);
      setText([...newArr]);
    }
  };
  const dropSelectList = async (open: boolean) => {
    if (!open) return;
    let res = await GetDatatextList({ pageSize: 999 });
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      setList(res.data.list);
    }
  };

  return (
    <div className="dataformedit_div">
      {
        <Modal
          visible={modalvisible}
          width={"1060px"}
          title={"字段详情"}
          onCancel={() => setModalVIsible(false)}
          zIndex={1051}
          footer={null}
        >
          <EditTextModal setList={setList} editText={editText} />
        </Modal>
      }
      <div className="dataformedit_head">
        <div className="addwords_div">
          <div>
            <Select
              defaultActiveFirstOption={false}
              style={{ minWidth: "200px" }}
              onChange={(value) => setSelect(value)}
              onDropdownVisibleChange={dropSelectList}
            >
              {list.map((i: any) => {
                return (
                  <Select.Option
                    key={i.id}
                    value={i.id}
                    disabled={addtext.includes(i.id) ? true : false}
                  >
                    {i.name}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div>
            <Button onClick={() => AddText(selected)}>添加</Button>
          </div>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
            }}
          >
            +自定义添加字段
          </Button>
        </div>
      </div>
      <div className="dataform_selected_div">
        <div className="dataform_selected">
          <DragSort
            onDragEnd={handleDragEnd}
            sortArr={addtext.map((i: number) => i)}
            data={list
              // .filter((i) => addtext.includes(i.id))
              .map((j: any) => {
                return {
                  ...j,
                  children: (
                    <div key={j.id} className="selected_keys_style">
                      <span className="datatext">{j.name}</span>
                      <Dropdown
                        placement={"bottomRight"}
                        trigger={["click"]}
                        overlay={
                          <div className="seletclick_list">
                            <div
                              onClick={() => {
                                setModalVIsible(true);
                                setEditDta(j);
                              }}
                            >
                              详情..
                            </div>
                            <div
                              onClick={() => {
                                RemoveText(j.id);
                              }}
                            >
                              移除
                            </div>
                          </div>
                        }
                      >
                        <EditOutlined />
                      </Dropdown>
                    </div>
                  ),
                };
              })}
          />
        </div>
        <div className="dataform_button_div">
          <Button onClick={() => EditFn(labelkey, true)}>取消</Button>
          <Button
            type="primary"
            style={{ marginLeft: "5px" }}
            onClick={() => EditPlat(labelkey, addtext)}
          >
            确定
          </Button>
        </div>
      </div>
    </div>
  );
};

let textDetail: any = {
  name: "字段名称",
  dataType: "字段类型",
  isUnique: "允许重复",
  isNotNull: "允许留空",
  dataSource: "输入类型",
  remark: "备注",
};
const EditTextModal = (props: any) => {
  // const [editData, setEditData] = useState<DataType>({});
  // const [changeData, setChange] = useState("");
  let { editText } = props;
  let arr = Object.keys(editText);
  let filterArr = arr.filter((i) => i !== "id");
  const textTypeinDetal = ["", "文本", "数值", "日期时间"];
  const dataSourceType = ["", "人工处理", "自动计算"];
  return (
    <Form initialValues={editText}>
      <Row gutter={24}>
        {filterArr.map((i: any) => {
          return (
            <Col span={12} key={i}>
              {i === "isUnique" || i === "isNotNull" ? (
                <Form.Item label={textDetail[i]}>
                  {editText[i] ? "是" : "否"}
                </Form.Item>
              ) : i === "dataType" ? (
                <Form.Item label={textDetail[i]}>
                  {textTypeinDetal[editText[i]]}
                </Form.Item>
              ) : i === "dataSource" ? (
                <Form.Item label={textDetail[i]}>
                  {dataSourceType[editText[i]]}
                </Form.Item>
              ) : (
                <Form.Item label={textDetail[i]}>{editText[i]}</Form.Item>
              )}
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};
const AddTextModal = (props: any) => {
  let { visible, setVisible } = props;
  const onFinish = async (value: datatextType) => {
    console.log(value);
    let res = await AddDatatext(value);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("添加字段成功", 0.4);
      setVisible(false);
    }
  };

  const initData = {
    name: "",
    dataType: 1,
    isUnique: true,
    isNotNull: true,
    dataSource: 1,
    remark: "",
  };
  return (
    <Modal
      visible={visible}
      title={"添加字段"}
      width={"1060px"}
      onCancel={() => setVisible(false)}
      footer={null}
      destroyOnClose
    >
      <Form onFinish={onFinish} initialValues={initData}>
        <div className="formContent">
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="字段名称"
                rules={[
                  {
                    required: true,
                    message: "请填写字段名称",
                  },
                  {
                    validator: (_: any, value: string) => {
                      return value
                        ? /^\s+$/g.test(value)
                          ? Promise.reject("请填写字段名称")
                          : Promise.resolve()
                        : Promise.resolve();
                    },
                  },
                ]}
                name={"name"}
              >
                <Input maxLength={20} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="字段类型"
                rules={[{ required: true, message: "请填写字段类型" }]}
                name={"dataType"}
              >
                <Select>
                  <Select.Option value={1}>文本</Select.Option>
                  <Select.Option value={2}>数值</Select.Option>
                  <Select.Option value={3}>日期时间</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="允许重复"
                rules={[{ required: true, message: "请填写字段名称" }]}
                name={"isUnique"}
              >
                <Select>
                  <Select.Option value={true}>是</Select.Option>
                  <Select.Option value={false}>否</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="允许留空"
                rules={[{ required: true, message: "请选择是否留空" }]}
                name={"isNotNull"}
              >
                <Select>
                  <Select.Option value={true}>是</Select.Option>
                  <Select.Option value={false}>否</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="输入类型"
                rules={[{ required: true, message: "请选择输入类型" }]}
                name={"dataSource"}
              >
                <Select>
                  <Select.Option value={1}>人工处理</Select.Option>
                  <Select.Option value={2}>自动计算</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="备注" name={"remark"}>
                <Input maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className="formfooter">
          <Form.Item className="buttonstyle">
            <Button className="btn-1" onClick={() => setVisible(false)}>
              取消
            </Button>
            <Button type="primary" className="btn-1" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export { DataTableCmp, AddTextModal };
