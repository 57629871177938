import { Button, Layout, Menu, MenuProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, {
  FC,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  BrowserRouter,
  Link,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import "./App.less";
import { Login } from "./login";
import Profile from "./profile/profile";
import { AddAcountModal } from "./router/account/Addacount";
import { AddPlatModal } from "./router/plat/addplat";
import { ParagraphTextDetail } from "./router/plat/paragraphtext";
import axios from "axios";
const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];

interface DataType {
  title: string;
  id: number;
  son: {
    src: string;
    title: string;
  }[];
  // | null
  // | undefined
}
const LoadingComponent = () => {
  return (
    <div className="global_loading">
      <LoadingOutlined />
    </div>
  );
};

const Loadable = (component: any) => {
  const Content = lazy(component);
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Content />
    </Suspense>
  );
};

const AccountComponent = Loadable(() => import("./router/account/account"));
const PlatComponent = Loadable(() => import("./router/plat/plat"));

const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<App2 />}>
          <Route path="fin-platform" element={PlatComponent}>
            <Route path="addPlattext" element={<ParagraphTextDetail />}>
              <Route path=":id" element={<ParagraphTextDetail />} />
            </Route>
            <Route path="addPlat" element={<AddPlatModal />}>
              <Route path=":id" element={<AddPlatModal />} />
            </Route>
          </Route>
          <Route path="fin-account" element={AccountComponent}>
            <Route path="addAccount" element={<AddAcountModal />}>
              <Route path=":id" element={<AddAcountModal />} />
            </Route>
          </Route>

          <Route path="user" element={<Profile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const App2: FC = () => {
  const [data, setData] = useState<DataType[]>([]);
  const history = useNavigate();
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    children?: MenuItem[]
  ): MenuItem {
    return { key, children, label } as MenuItem;
  }

  const getNavMenu = useCallback(async () => {
    let arr: DataType[] = [];
    try {
      let res = await axios.get("/menus");

      //获取导航栏数据
      for (let i of res.data.data.Menus) {
        if (i.parentId === 0) {
          arr.push({
            title: i.name,
            id: i.id,
            son: [],
          });
        } else {
          arr.forEach((j) => {
            if (j.id === i.parentId) {
              j.son.push({ title: i.name, src: i.path });
            }
          });
        }
      }
      setData(arr);
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    getNavMenu();
  }, [getNavMenu]);
  const items: MenuProps["items"] = data.map((i) => {
    return getItem(
      i.title,
      i.id,
      i.son.map((j) =>
        getItem(
          <Link key={j.src} to={`/home${j.src}`}>
            {j.title}
          </Link>,
          j.src
        )
      )
    );
  });

  return (
    <div style={{ height: "100%" }}>
      <Layout style={{ minHeight: "100%", backgroundColor: "#dddddd" }}>
        <Sider theme="light" style={{ margin: "8px 0 8px 8px" }}>
          <div className="userinfor">
            <div className="administer_div">
              <div>你好！管理员</div>
            </div>
            <div className="settingBox">
              <Button
                onClick={() => {
                  history("/home/user");
                }}
              >
                设置
              </Button>
              <Button
                onClick={() => {
                  localStorage.removeItem("jwt");
                  history("/");
                }}
              >
                退出登录
              </Button>
            </div>
          </div>
          <Menu
            theme="light"
            mode="inline"
            items={items}
            defaultSelectedKeys={["/home/fin-account"]}
            defaultOpenKeys={["/home/fin-account"]}
          />
        </Sider>
        <Layout className="content-right">
          <Outlet />
        </Layout>
      </Layout>
    </div>
  );
};

export default App;
