import { message } from "antd";
import axios from "axios";

const AllUrl = require("./devloader.json");
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.jwt;
    config.baseURL = AllUrl.backendUrl;
    config.timeout = 300000;
    config.headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    return config;
  },
  (err) => {
    message.error("网络错误");
    return Promise.reject(err);
  }
);

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err?.response.status === 504 || err?.response.status === 404) {
      message.error("服务器错误");
      return Promise.reject("服务器错误");
    } else if (err?.response.status === 401) {
      console.log("11");
      window.localStorage.jwt = "";
      window.location.href = "/?code=1";
      return Promise.reject("");
    } else {
      message.error("未知错误");
      return Promise.reject("未知错误");
    }
  }
);

export default axios;
