import { Forms } from "../../component/forms";

const ParagraphTextDetail = () => {
  const componentaArr = [
    {
      type: "input",
      label: "字段名称",
      key: "platname",
      action: "input",
      config: { maxLength: 20 },
      style: {
        width: "300px",
      },
      required: [
        {
          required: true,
          message: "请填写平台名称",
        },
      ],
    },
    {
      type: "select",
      label: "字段类型",
      action: "select",
      key: "textclassify",
      style: {
        width: "300px",
      },
      children: [
        {
          value: "text",
          label: "文本",
        },
      ],
      required: [
        {
          required: true,
          message: "请选择文本类型",
        },
      ],
    },
    {
      type: "select",
      label: "允许重复",
      action: "select",
      key: "isrepeat",
      style: {
        width: "300px",
      },
      children: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 2,
          label: "否",
        },
      ],
    },
    {
      type: "select",
      label: "允许留空",
      action: "select",
      key: "isempty",
      style: {
        width: "300px",
      },
      children: [
        {
          value: 1,
          label: "是",
        },
        {
          value: 2,
          label: "否",
        },
      ],
    },
    {
      type: "input",
      label: "备注",
      action: "input",
      key: "tips",
      style: {
        width: "300px",
      },
      config: { maxLength: 100 },
    },
  ];
  return <Forms componentaArr={componentaArr} width={"1060px"}></Forms>;
};
export { ParagraphTextDetail };
