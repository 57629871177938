import { Button, message, Popconfirm } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { GetDatatextList } from "../../component/dataTextApi";

import { Forms } from "../../component/forms";
import { GetAccountList } from "../account/accountApi";
import { Addplatpost, DeletPlat, Editplat, GetIdPlat } from "./plar_Api";
import { PlatDataType } from "./platinterface";

const AddPlatModal = (props: any) => {
  const [data, setData] = useState({
    currency: "CNY",
  });
  const [accountlist, setAccount] = useState([]);
  const [dataText, setDataText] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  const [reloadFn, contextdata] = useOutletContext<
    [
      (params: any) => void,
      {
        darasSource: any[];
        loading: boolean;
        pageNumber: number;
        pageSize: number;
        total: number;
      }
    ]
  >();
  let { pageNumber, pageSize } = contextdata;
  const componentaArr = [
    {
      type: "input",
      label: "平台名称",
      key: "name",
      action: "input",
      config: { maxLength: 20 },
      required: [
        {
          required: true,
          message: "请填写平台名称",
        },
        {
          validator: (_: any, value: string) => {
            return value
              ? /^\s+$/g.test(value)
                ? Promise.reject("请填写平台名称")
                : Promise.resolve()
              : Promise.resolve();
          },
        },
      ],
    },
    {
      type: "select",
      label: "货币类型",
      action: "select",
      key: "currency",
      children: [
        {
          value: "CNY",
          label: "人民币",
        },
        { value: "TWD", label: "新台币" },
      ],
      required: [
        {
          required: true,
          message: "请选择货币类型",
        },
      ],
    },
    {
      type: "input",
      label: "备注",
      action: "input",
      key: "remark",
      config: { maxLength: 100 },
    },
    {
      type: "text",
      label: "数据表字段",
      action: "text",
      key: "dataTableColumns",
    },
    {
      type: "relate",
      label: "关联账户",
      action: "relate",
      key: "relatedToAccounts",
    },
  ];
  let titleText = params.id ? "平台详情" : "添加平台";

  const reload = useCallback(async (id: any) => {
    let res = await GetIdPlat(id);
    if (res.code !== 0) {
      message.error(res.message);
      return;
    } else {
      setData(res.data);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      reload(params.id);
    }
  }, [reload, params]);

  const onSubmit = async (values: any) => {
    let res = await Addplatpost(values);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("添加成功");
      navigate(-1);
      reloadFn({ pageNumber, pageSize });
    }
  };
  const submitPlat = async (value: PlatDataType) => {
    let res = await Editplat(params.id, value);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("修改成功", 0.4);
      reload(params.id); //刷新当前弹窗数据
      reloadFn({ pageNumber, pageSize }); //刷新Table
    }
  };
  const deletaccount = async (id: any) => {
    let res = await DeletPlat(id);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      message.success("删除成功");
      navigate(-1); //关闭弹窗，回到上个路由
      reloadFn({ pageNumber, pageSize }); //刷新Table
    }
  };

  const getAccount = async () => {
    let res = await GetAccountList({ pageSize: 999 });
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      setAccount(res.data.list);
    }
  };

  const getDatatext = async () => {
    let res = await GetDatatextList({ pageSize: 999 });
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
    } else {
      setDataText(res.data.list);
    }
  };
  useEffect(() => {
    if (params.id) {
      getAccount();
      getDatatext();
      // getDatatext();
    }
  }, [params.id]);
  const DeletCmp = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Popconfirm
        title="是否确认删除？"
        onConfirm={() => deletaccount(params.id)}
      >
        <Button type="link" style={{ fontSize: "16px" }}>
          删除此平台
        </Button>
      </Popconfirm>
    </div>
  );
  return (
    <>
      <Forms
        modalTitle={titleText}
        width={"1060px"}
        componentaArr={componentaArr}
        initValues={data}
        onSubmit={onSubmit}
        submitEdit={submitPlat}
        DeletCmp={DeletCmp}
        accountlist={accountlist}
        dataText={dataText}
      />
    </>
  );
};

export { AddPlatModal };
