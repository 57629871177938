const currencyType: {
  [key: string]: string;
} = {
  CNY: "人民币",
  TWD: "新台币",
};

const dataTypeIndex = [
  <div></div>,
  <div className="datatextstyle1"></div>,
  <div className="datatextstyle2"></div>,
  <div className="datatextstyle3"></div>,
];
export { currencyType, dataTypeIndex };
