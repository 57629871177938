import axios from "axios";

export interface datatextType {
  id?: number;
  name: string;
  dataType: number;
  isUnique: boolean;
  isNotNull: boolean;
  remark?: string;
  dataSource: number;
}

export const AddDatatext = async (data: datatextType) => {
  let res = await axios.post("/finance/columns", data);
  return res.data;
};

export const GetDatatextList = async (params: { pageSize: number }) => {
  let res = await axios.get("/finance/columns", { params });
  return res.data;
};
