import { useSortable } from "@dnd-kit/sortable";
import { forwardRef } from "react";
import { CSS } from "@dnd-kit/utilities";
export const Item = forwardRef(({ ...props }: any, ref: any) => {
  return (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  );
});

export const SortableItem = (props: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Item
      className={isDragging ? "active_opacity" : ""}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {props.children}
    </Item>
  );
};
