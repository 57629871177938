import { Form, Modal, Row, Col, Input, Button, Select } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { AddTextModal, DataTableCmp } from "./dataTable";
import { RelateCmp } from "./relateaccount";
import { datatextType } from "./dataTextApi";
import { dataTypeIndex } from "../global_cig";
interface formType {
  type?: string;
  label: string;
  action?: string;
  key: string;
  config?: object;
  style?: object;
  required?: {}[] | [];
  children?: { value: string; label: string }[];
}
interface DataType {
  [key: string]: boolean;
}

const Forms = (props: any) => {
  let {
    modalTitle,
    width,
    componentaArr,
    initValues,
    onSubmit,
    submitEdit,
    DeletCmp,
    accountlist,
    dataText,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [editData, setEditData] = useState<DataType>({});
  const [visible, setVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const isAdd = location.pathname
    .split("/")
    [location.pathname.split("/").length - 1].startsWith("add");

  const EditFn = (key: string, iscancel: boolean) => {
    //具体点开了哪一项编辑
    let dataclick: DataType = {};
    dataclick[key] = iscancel ? false : true;
    setEditData(dataclick);
    form.resetFields();
  };

  //每个平台的每项单独编辑
  const EditPlat = async (key: string, value: any) => {
    let filterArr = [
      "name",
      "remark",
      "currency",
      "relatedToAccounts",
      "dataTableColumns",
    ];
    let record = initValues;
    record[key] = value;
    submitEdit(record);
    if (filterArr.indexOf(key) !== -1) {
      EditFn(key, true);
    }
  };

  //添加时获取初始值
  useEffect(() => {
    form2.setFieldsValue(props.initValues);
  }, [props, form2]);

  //查找出对应的关联账户
  let resultAccount = accountlist?.filter((i: any) => {
    return initValues.relatedToAccounts?.includes(i.id);
  });

  //查找出该平台对应的数据表字段
  let resultDataText = initValues.dataTableColumns?.reduce(
    (prev: any, next: any) => [
      ...prev,
      dataText.find((i: any) => i.id === next),
    ],
    []
  );

  return (
    <>
      {<AddTextModal setVisible={setVisible} visible={visible} />}
      <Modal
        title={modalTitle}
        width={width}
        visible={true}
        maskClosable={false}
        onCancel={() => {
          navigate(-1);
        }}
        footer={false}
        className="modalstyle"
      >
        {isAdd ? (
          <Form onFinish={onSubmit} form={form2}>
            <div className="formContent">
              <Row gutter={24}>
                {componentaArr.map((i: formType) => {
                  return i.action === "select" ? (
                    <Col span={12} key={i.key}>
                      <Form.Item
                        label={i.label}
                        name={i.key}
                        rules={i.required}
                      >
                        <Select style={i.style}>
                          {i.children?.map((i) => {
                            return (
                              <Select.Option value={i.value} key={i.value}>
                                {i.label}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : i.action === "input" ? (
                    <Col span={12} key={i.key}>
                      <Form.Item
                        name={i.key}
                        label={i.label}
                        rules={i.required}
                      >
                        <Input {...i.config} style={i.style} />
                      </Form.Item>
                    </Col>
                  ) : i.action === "text" ? (
                    <Col span={24} key={i.key}>
                      <div className="dataformstext">
                        {i.label}（创建平台后可编辑）
                      </div>
                    </Col>
                  ) : i.action === "relate" ? (
                    <Col span={24} key={i.key}>
                      <div className="dataformstext">
                        {i.label}（创建平台后可编辑）
                      </div>
                    </Col>
                  ) : (
                    ""
                  );
                })}
              </Row>
            </div>
            <div className="formfooter">
              <Form.Item className="buttonstyle">
                <Button className="btn-1" onClick={() => navigate(-1)}>
                  取消
                </Button>
                <Button type="primary" className="btn-1" htmlType="submit">
                  确定
                </Button>
              </Form.Item>
            </div>
          </Form>
        ) : (
          <Form form={form} initialValues={initValues}>
            <Row gutter={24}>
              {componentaArr.map((i: formType) => {
                return i.action === "input" ? (
                  <Col span={12} key={i.key}>
                    <div className="forminput">
                      <div className="labelwidth">{i.label}:</div>
                      {editData[i.key] ? (
                        <SubmitInputCmp
                          rules={i.required}
                          name={i.key}
                          config={i.config}
                          EditFn={EditFn}
                          labelkey={i.key}
                          EditPlat={EditPlat}
                        />
                      ) : (
                        <div className="iconinline">
                          <div className="formiconleft">
                            {initValues[i.key]}
                          </div>
                          <div>
                            <EditOutlined
                              onClick={() => EditFn(i.key, false)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                ) : i.action === "select" ? (
                  <Col span={12} key={i.key}>
                    <div className="forminput">
                      <div className="labelwidth">{i.label}:</div>
                      {editData[i.key] ? (
                        <EditSelectCmp
                          defaultValue={initValues}
                          EditFn={EditFn}
                          labelkey={i.key}
                          selectOptions={i.children}
                          EditPlat={EditPlat}
                        />
                      ) : (
                        <div className="iconinline">
                          <div className="formiconleft">
                            {
                              i.children?.filter(
                                (j) => j.value === initValues[i.key]
                              )[0]?.label
                            }
                          </div>
                          <div>
                            <EditOutlined
                              onClick={() => EditFn(i.key, false)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                ) : i.action === "text" ? (
                  <Col span={24} key={i.key}>
                    {editData[i.key] ? (
                      <div className="dataform_text">
                        <div className="labelwidth">{i.label}:</div>
                        <DataTableCmp
                          defaultValue={initValues.dataTableColumns}
                          EditFn={EditFn}
                          EditPlat={EditPlat}
                          setVisible={setVisible}
                          labelkey={i.key}
                          navigate={navigate}
                          dataText={dataText}
                        />
                      </div>
                    ) : (
                      <div className="dataformshow">
                        <div className="dataforminline">
                          <div className="labelwidth">{i.label}：</div>
                          <div style={{ marginLeft: "5px" }}>
                            <EditOutlined
                              onClick={() => EditFn(i.key, false)}
                            />
                          </div>
                        </div>

                        <div className="dataform_map_box">
                          {resultDataText?.map(
                            (j: datatextType, index: number) => {
                              return (
                                <div className="datatextstyle" key={index}>
                                  {dataTypeIndex[j?.dataType]}
                                  {j?.name}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </Col>
                ) : i.action === "relate" ? (
                  <Col span={24} key={i.key}>
                    {editData[i.key] ? (
                      <div className="dataform_text">
                        <div className="labelwidth">{i.label}：</div>
                        <RelateCmp
                          accountlist={accountlist}
                          EditFn={EditFn}
                          labelkey={i.key}
                          EditPlat={EditPlat}
                          name={i.key}
                        />
                      </div>
                    ) : (
                      <div className="dataformshow">
                        <div className="dataforminline">
                          <div className="labelwidth">{i.label}：</div>
                          <div style={{ marginLeft: "5px" }}>
                            <EditOutlined
                              onClick={() => EditFn(i.key, false)}
                            />
                          </div>
                        </div>

                        <div className="dataform_map_div">
                          <Row gutter={24}>
                            {resultAccount?.length > 0
                              ? resultAccount.map((i: any) => {
                                  return (
                                    <Col
                                      span={8}
                                      key={i.id}
                                      style={{ margin: "10px 0" }}
                                    >
                                      {i.name}
                                    </Col>
                                  );
                                })
                              : ""}
                          </Row>
                        </div>
                      </div>
                    )}
                  </Col>
                ) : (
                  ""
                );
              })}
            </Row>
          </Form>
        )}
        <div className="delete_footer">
          {
            //是否有
            !isAdd && DeletCmp
          }
        </div>
      </Modal>
    </>
  );
};

//编辑时的Input组件
const SubmitInputCmp = (props: any) => {
  let { EditFn, labelkey, EditPlat, config, rules, name } = props;
  const form = Form.useFormInstance();
  return (
    <div className="forminput_btn">
      <div>
        <Form.Item name={name} rules={rules}>
          <Input maxLength={config.maxLength} />
        </Form.Item>
      </div>
      <div>
        <Button
          onClick={() => {
            EditFn(labelkey, true);
          }}
        >
          取消
        </Button>
        <Button
          type="primary"
          onClick={() =>
            form.validateFields().then((values) => {
              console.log(values);
              EditPlat(labelkey, values.remark);
            })
          }
        >
          确定
        </Button>
      </div>
    </div>
  );
};
//编辑状态时的下拉框
const EditSelectCmp = (props: any) => {
  let { defaultValue, EditFn, labelkey, selectOptions, EditPlat } = props;
  let initValue = defaultValue.currency;
  return (
    <div className="forminput_btn">
      <div>
        <Select
          style={{ width: "200px" }}
          defaultValue={selectOptions.filter(
            (i: { value: string; label: string }) =>
              i.value === defaultValue.currency
          )}
          onSelect={(value: string) => {
            initValue = value;
          }}
        >
          {selectOptions.map((i: { value: string; label: string }) => (
            <Select.Option key={i.value}>{i.label}</Select.Option>
          ))}
        </Select>
      </div>
      <div>
        <Button onClick={() => EditFn(labelkey, true)}>取消</Button>
        <Button
          type="primary"
          onClick={() => {
            EditPlat(labelkey, initValue);
          }}
        >
          确定
        </Button>
      </div>
    </div>
  );
};

export { Forms };
