import { FC } from "react";

const Profile: FC = () => {
  return (
    <div>
      我是个人设置页面
      <img
        alt="222"
        width={"100%"}
        height="100%"
        src="https://krenz.oss-cn-shanghai.aliyuncs.com/b4afc5b83d74a03baac19dcc04f5e2f4"
      />
    </div>
  );
};

export default Profile;
