import axios from "axios";

export const GetAccountList = async (params?: any) => {
  let res = await axios.get("/finance/accounts", { params });
  return res.data;
};

export const AddAccountPost = async (data: {
  name: string;
  remark: string;
}) => {
  let res = await axios.post("/finance/accounts", data);
  return res.data;
};

export const GetIdAccount = async (id: number) => {
  let res = await axios.get(`/finance/accounts/${id}`);
  return res.data;
};

export const EditAccount = async (
  id: string | undefined,
  data: { id: number; name: string; remark: string }
) => {
  let res = await axios.put(`/finance/accounts/${id}`, data);
  return res.data;
};

export const DeletAccount = async (id: string | undefined) => {
  let res = await axios.delete(`/finance/accounts/${id}`);
  return res.data;
};
